import * as Sentry from '@sentry/browser'

export default {
  Sentry,

  installSentry(opts) {
    const opts_with_dsn = Object.assign({}, {dsn: 'https://4652ed071f414f7a81df8ee322b2d54f@sentry.io/41079'}, opts)

    Sentry.init(opts_with_dsn);
  }
}
